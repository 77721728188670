import React, { FC } from 'react';
import { SVGProps } from '../types';

/**
 * RedisGears
 */
export const RedisGears: FC<SVGProps> = ({ size, fill, ...rest }) => {
  return (
    <a
      target="_blank"
      href="http://redisgears.io"
      title="RedisGears is a serverless engine for transaction, batch and event-driven data processing in Redis."
    >
      <svg version="1.1" id="RedisGears" x="0px" y="0px" viewBox="0 0 32 32" width={size} height={size} {...rest}>
        <path
          fill={fill ? fill : '#DC382D'}
          d="M23,16c0-3.9-3.1-7-7-7s-7,3.1-7,7s3.1,7,7,7S23,19.9,23,16z M16,22c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6
        S19.3,22,16,22z M24.5,3.9c-0.6,0.6-1.6,0.8-2.4,0.4c-0.7-0.4-1.4-0.7-2.2-0.9c-0.9-0.3-1.4-1.1-1.4-2V0h-1v1.4c0,1.3,0.9,2.5,2.1,3
        c0.7,0.2,1.4,0.5,2,0.8C22,5.4,22.5,5.5,23,5.5c0.8,0,1.6-0.3,2.2-0.9l1-1l-0.7-0.7L24.5,3.9z M30.6,13.5c-0.9,0-1.7-0.6-2-1.4
        c-0.2-0.8-0.5-1.5-0.9-2.2c-0.4-0.8-0.2-1.8,0.4-2.4l1-1l-0.7-0.7l-1,1c-1,0.9-1.2,2.4-0.6,3.6c0.3,0.6,0.6,1.3,0.8,2
        c0.4,1.3,1.6,2.1,3,2.1H32v-1H30.6L30.6,13.5z M27.6,19.6c-0.2,0.7-0.5,1.4-0.8,2c-0.6,1.2-0.4,2.7,0.6,3.6l1,1l0.7-0.7l-1-1
        c-0.6-0.6-0.8-1.6-0.4-2.4c0.4-0.7,0.7-1.4,0.9-2.2c0.3-0.9,1.1-1.4,2-1.4H32v-1h-1.4C29.2,17.5,28,18.3,27.6,19.6z M21.6,26.8
        c-0.6,0.3-1.3,0.6-2,0.8c-1.3,0.4-2.1,1.6-2.1,3V32h1v-1.4c0-0.9,0.6-1.7,1.4-2c0.8-0.2,1.5-0.5,2.2-0.9c0.8-0.4,1.8-0.2,2.4,0.4
        l1,1l0.7-0.7l-1-1C24.3,26.4,22.8,26.2,21.6,26.8z M12.4,27.6c-0.7-0.2-1.4-0.5-2-0.8c-1.2-0.6-2.7-0.4-3.6,0.6l-1,1l0.7,0.7l1-1
        c0.6-0.6,1.6-0.8,2.4-0.4c0.7,0.4,1.4,0.7,2.2,0.9c0.9,0.3,1.4,1.1,1.4,2V32h1v-1.4C14.5,29.2,13.7,28,12.4,27.6z M4.4,19.6
        c-0.4-1.3-1.6-2.1-3-2.1H0v1h1.4c0.9,0,1.7,0.6,2,1.4c0.2,0.8,0.5,1.5,0.9,2.2c0.4,0.8,0.2,1.8-0.4,2.4l-1,1l0.7,0.7l1-1
        c1-0.9,1.2-2.4,0.6-3.6C4.9,21,4.6,20.3,4.4,19.6z M3.6,5.7L2.9,6.4l1,1C4.5,8,4.7,9,4.3,9.8c-0.4,0.7-0.7,1.4-0.9,2.2
        c-0.3,0.9-1.1,1.4-2,1.4H0v1h1.4c1.3,0,2.5-0.9,3-2.1c0.2-0.7,0.5-1.4,0.8-2c0.6-1.2,0.4-2.7-0.6-3.6L3.6,5.7z M9,5.6
        c0.5,0,1-0.1,1.4-0.3c0.6-0.3,1.3-0.6,2-0.8c1.3-0.4,2.1-1.6,2.1-3V0h-1v1.4c0,0.9-0.6,1.7-1.4,2C11.3,3.7,10.6,4,9.9,4.3
        C9.1,4.7,8.1,4.6,7.5,3.9l-1-1L5.7,3.6l1,1C7.3,5.2,8.1,5.6,9,5.6z"
        />
      </svg>
    </a>
  );
};
